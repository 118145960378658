<template>
    <el-dialog
        title="在线支付"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        append-to-body
        custom-class="el-dialog--full"
        width="654px"
        @close="handleToClose"
    >
        <div v-loading="loading" class="pay-container">
            <div class="pay-aside">
                <div
                    v-for="item in payTypeData"
                    :key="item.name"
                    class="pay-type"
                    :class="{ checked: item.name === payType }"
                    @click="handleCheckPayType(item)"
                >
                    <img :src="item.ico" alt="weixin" />
                </div>

                <!-- <el-button type="primary" @click="handlePaySuccess">模拟支付成功</el-button> -->

                <div class="pay-tips">
                    <p>· 支付后可在个人中心 <br />- 我的订单，申请发票</p>
                    <p v-if="orderInfo.orderType !== '付费下载报告'">
                        · VIP会员自支付完成之时起5分钟内生效
                    </p>
                </div>
            </div>
            <div class="pay-main">
                <div class="pay-warning">
                    &nbsp;
                    <!-- 只可解锁座机号码，请谨慎付费 -->
                </div>

                <div class="pay-ewm">
                    <div class="pay-ewm-img" @click="handleGetPayEwm">
                        <el-image :src="payEwmIco">
                            <div slot="error" class="image-slot">
                                <div class="ewm-error">
                                    <i class="el-icon-refresh"></i>
                                    <span>点击重新获取</span>
                                </div>
                            </div>
                        </el-image>
                    </div>

                    <div class="pay-ewm-tips">{{ payEwmTips }}</div>
                </div>

                <div class="order-info">
                    <div class="info-item">
                        <span class="info-item-label">服务类型：</span>
                        <span class="info-item-value">{{ orderTypeTxt }}</span>
                    </div>

                    <div v-if="orderInfo.orderType !== '付费下载报告'">
                        <div class="info-item">
                            <span class="info-item-label">开通时长：</span>
                            <span class="info-item-value"
                                >{{ orderInfo.vipDuration }}个月</span
                            >
                        </div>
                        <div v-if="expirationTime" class="info-item">
                            <span class="info-item-label">到期时间：</span>
                            <span class="info-item-value">{{
                                expirationTime
                            }}</span>
                        </div>
                    </div>

                    <div
                        v-else-if="orderInfo.orderType === '付费下载报告'"
                        class="info-item"
                    >
                        <span class="info-item-label">支付类型：</span>
                        <span class="info-item-value">按次付费</span>
                    </div>

                    <div class="info-item">
                        <span class="info-item-label">实付金额：</span>
                        <span class="info-item-value">
                            <span class="amount">{{
                                orderInfo.orderAmount | filterThousandth
                            }}</span>
                            <span>元</span>
                        </span>
                    </div>

                    <!-- <div v-if="orderInfo.orderType === '3'" class="info-item">
                        <span class="info-item-label">次季续费：</span>
                        <span class="info-item-value">99元</span>
                    </div> -->
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import dayjs from 'dayjs';

import { ApiPayWechat } from '/src/api/pay';
import { ApiOrderQuery } from '/src/api/order';

import { getStaticMap } from '/src/utils/staticData';

import { track } from '/src/directive/track/sendData';

export default {
    name: 'CorderPay',
    data() {
        return {
            dialogVisible: false,
            loading: false,
            orderQuerying: false,
            paySuccess: false,

            payType: '',
            // 支付方式： 1：支付宝 2：微信
            payTypeData: [
                {
                    name: 'weixin',
                    ico: '/images/pay-ico-weixin.png',
                    tips: '打开手机微信扫码继续付款',
                    value: 2,
                },
                {
                    name: 'zhifubao',
                    ico: '/images/pay-ico-zhifubao.png',
                    tips: '打开手机支付宝扫码继续付款',
                    value: 1,
                },
            ],

            payEwmIco: '',
            payEwmTips: '',

            // 会员到期时间
            expirationTime: '',
            // 会员类型对应的年份
            expirationTimeData: [0, 3, 1, 0, 0, 5],

            staticMap: getStaticMap(),

            // orderType 订单类型 后台提供 1-会员服务 2-续费会员 4-数据导出
            orderTypeTxt: '',

            orderInfo: {
                // createTime		integer(int64)
                // creatorGuid		string
                // exportCount	导出数量	integer(int32)
                // guid	订单id	string
                // invoiceType	开票状态 10-未开票 20-开票中 30-开票失败 40-已开票 50-已寄出 60-未确认 70-已线下开票 80-暂不可开	integer(int32)
                // isDeleted		boolean
                // modifierGuid		string
                // modifyTime		integer(int64)
                // orderAmount	订单金额	number
                // orderNo	订单编号	string
                // orderType 订单类型 后台提供 1-会员服务 2-续费会员 4-数据导出
                // payFeedback		string
                // payStatus	支付状态 false：未支付 true：已支付	boolean
                // payTime		integer(int64)
                // payType	支付方式： 1：支付宝 2：微信	integer(int32)
                // queryParam	查询条件	string
                // userGuid		string
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
        vipFlag() {
            return this.$store.getters.vipFlag || false;
        },
    },
    created() {},
    mounted() {
        // this.handleOpen({
        //     guid: '12312',
        // });
        // console.log(dayjs(parseInt('1649833802230')).add(3, 'year').format('YYYY年MM月DD日'));
    },
    methods: {
        handleOpen(info) {
            const { userInfo, vipFlag, staticMap } = this;

            this.orderInfo = info || {};

            if (!info || !info.guid) {
                this.$message.error('订单ID为空！');
                return false;
            }

            // 订单类型文字
            this.orderTypeTxt = info.orderType;

            // staticMap.orderTypeMap[info.orderType + ''] || '-';

            if (info.payStatus) {
                // 支付状态为true，不再发起支付
                return false;
            }

            // 会员到期时间
            let tmpExpirationTime = '';

            // if (info.orderType !== '4') {
            // 从用户信息获取会员到期时间，或者获取订单的创建时间
            const tmpExpirationDate = vipFlag
                ? userInfo.expirationDate
                : parseInt(info.createTime);

            // 需要后端支持计算
            tmpExpirationTime = dayjs(tmpExpirationDate)
                .add(parseInt(info.vipDuration), 'month')
                .format('YYYY年MM月DD日');

            // console.log(tmpExpirationDate, info.orderType, info.vipDuration, tmpExpirationTime);
            // }

            this.expirationTime = tmpExpirationTime;

            this.payType = 'weixin';
            this.handleDataFormat();

            this.dialogVisible = true;
            this.paySuccess = false;
        },

        // 数据初始化
        handleDataFormat() {
            const { payType, payTypeData } = this;

            payTypeData.forEach(item => {
                if (payType === item.name) {
                    this.handleCheckPayType(item);
                }
            });
        },

        // 选中
        handleCheckPayType(item) {
            this.payType = item.name;
            this.payEwmIco = '';
            this.payEwmTips = item.tips;

            this.handleGetPayEwm();
        },

        // 获取微信支付二维码
        handleGetPayEwm() {
            const { payType, payTypeData, orderInfo, orderQuerying } = this;
            const tmpPayTypeValue = payTypeData.filter(
                item => item.name === payType
            )[0]?.value;

            if (this.loading || !tmpPayTypeValue) {
                return false;
            }

            this.loading = true;

            ApiPayWechat({
                orderId: orderInfo.guid,
                payType: tmpPayTypeValue,
            })
                .then(res => {
                    this.loading = false;

                    // 支付二维码
                    this.payEwmIco = 'data:image/png;base64,' + res.data;

                    // 开始轮询支付状态
                    if (!orderQuerying) {
                        // 轮询只触发一次
                        this.handleGetOrderState();
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        // 轮询获取订单状态
        handleGetOrderState() {
            const { orderInfo, dialogVisible } = this;

            if (!dialogVisible) {
                // 弹出框关闭，停止轮询
                return false;
            }

            this.orderQuerying = true;

            ApiOrderQuery({
                orderId: orderInfo.guid,
                orderNo: orderInfo.orderNo,
            })
                .then(res => {
                    if (!res.data) {
                        setTimeout(() => {
                            this.handleGetOrderState();
                        }, 2000);
                    } else {
                        this.$message.success('支付成功！');

                        this.dialogVisible = false;
                        this.orderQuerying = false;
                        this.paySuccess = true;

                        this.$emit('success', orderInfo);
                    }
                })
                .catch(() => {
                    this.orderQuerying = false;
                });
        },

        // 关闭的回调
        handleToClose() {
            if (!this.paySuccess) {
                // 未支付成功，关闭弹出框
                const { orderInfo } = this;

                // 上报
                track('t:click_paypopup_close;p:' + orderInfo.orderType);
            }

            this.paySuccess = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.pay-container {
    display: flex;
    height: 440px;
}

.pay-aside {
    position: relative;
    width: 210px;
    height: 100%;
    background-color: #e4f8fe;
}

.pay-type {
    height: 92px;
    padding: 0 28px;
    line-height: 92px;
    cursor: pointer;

    img {
        display: inline-block;
        vertical-align: middle;
    }

    &.checked {
        background-color: #fff;
    }
}

.pay-tips {
    position: absolute;
    right: 32px;
    bottom: 30px;
    left: 32px;
    color: #1d1d1d;
    font-size: 12px;
    line-height: 18px;
    white-space: pre-line;

    p {
        + p {
            margin-top: 15px;
        }
    }
}

.pay-main {
    flex: 1;
    height: 100%;
    background-color: #fff;
}

.pay-warning {
    margin-bottom: 12px;
    padding-top: 26px;
    color: #e03a3e;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.pay-ewm {
    margin-bottom: 25px;
    color: #888;
    font-size: 12px;
    text-align: center;

    &-img {
        margin-bottom: 18px;
    }

    .el-image {
        display: inline-block;
        width: 180px;
        height: 180px;
        line-height: 180px;
        background-color: #ccc;
        cursor: pointer;
    }
}

.order-info {
    margin-left: 108px;
    color: #1d1d1d;
    font-size: 18px;
    line-height: 30px;

    .amount {
        color: #e03a3e;
        font-size: 24px;
    }
}
</style>
