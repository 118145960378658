import axios from 'axios';
import store from '/src/store';

/**
 * 参数格式化
 * @param {*} str
 */
function paramsFormat(str) {
    const tmpParams = {};

    str.split(';').forEach(item => {
        if (item) {
            const tmpValue = item.split(':');

            tmpParams[tmpValue[0]] = tmpValue[1] || '';
        }
    });

    return tmpParams;
}

/**
 * 事件上报
 * @param {Object} params
 */
export function track(params) {
    const { token } = store.getters;

    // 这里自己封装fetch或者axios，在拦截器中实现公共参数上报
    // /clickEvent/saveEvent
    // d 点击描述 id 公司ID name 公司名称 p 自定义参数 t 点击类型

    // 用分号分隔字段，用冒号分隔键名和键值
    let tmpParams = {};

    if (typeof params === 'object' && params.length) {
        // 监听曝光合并上报
        params.forEach(str => {
            tmpParams = Object.assign(tmpParams, paramsFormat(str));
        });
    } else if (typeof params === 'string') {
        // 点击或单个上报
        tmpParams = paramsFormat(params);
    }

    // console.log(params);

    if (JSON.stringify(tmpParams) === '{}') {
        return false;
    }

    // console.log(tmpParams);

    // axios.get(import.meta.env.VITE_BASE_API + '/clickEvent/saveEvent?ID=12345');
    axios({
        method: 'get',
        url: import.meta.env.VITE_BASE_API + '/clickEvent/saveEvent',
        headers: {
            'content-type': 'application/json',
            token,
        },
        withCredentials: true,
        params: tmpParams,
    });
}
