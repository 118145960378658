import Layout from '/src/layout/Layout.vue'

// import membershipIndex from '/src/views/membership/index.vue'
const membershipIndex  = () => import('/src/views/membership/index.vue');

const membershipRouter = {
    path: '/membership',
    component: Layout,
    redirect: '/membership/index',
    name: 'membership',
    meta: { title: '会员权益' },
    children: [
        {
            path: 'index',
            component: membershipIndex,
            name: 'membershipIndex',
            meta: { title: '会员权益', navCurrent: 'membership' },
        },
    ],
}

export default membershipRouter
