import Vue from 'vue';
import Exposure from './exposure';
import Click from './click';

// 实例化曝光和点击
const exp = new Exposure();
const cli = new Click();

Vue.directive('track', {
    // 调用指令声明周期钩子函数bind，其他钩子函数请移步官网
    bind(el, binding) {
        // 获取指令参数
        const { arg } = binding;
        arg.split('|').forEach(item => {
            // 点击
            if (item === 'click') {
                cli.add({ el });
            } else if (item === 'exposure') {
                exp.add({ el });
            }
        });
    },
});
