import Layout from '/src/layout/Layout.vue';
// import otherErr404Component from '/src/views/error/404.vue';
// import otherEedirectComponent from '/src/views/error/redirect.vue';

const otherErr404Component = () => import('/src/views/error/404.vue');
const otherEedirectComponent = () => import('/src/views/error/redirect.vue');

const otherRouter = [
    {
        path: '/error',
        component: Layout,
        hidden: true,
        redirect: '/error/404',
        name: 'errorPage',
        meta: {
            title: '404页面找不到',
            oops: '页面不见了！',
        },
        children: [
            {
                path: '404',
                component: otherErr404Component,
                name: 'error404',
                meta: {
                    title: '404页面找不到',
                    oops: '页面不见了！',
                    column: 'error404',
                    pname: 'errorPage',
                },
            },
        ],
    },
    {
        path: '/error/405',
        component: otherErr404Component,
        hidden: true,
        name: 'error405',
        meta: {
            title: '405页面无法访问',
            oops: '页面无法访问！',
            white: true,
        },
    },
    {
        path: '/redirect',
        component: otherEedirectComponent,
        hidden: true,
        name: 'redirectPage',
        meta: {
            white: true,
            title: '重定向',
            white: true,
        },
    },
];

export default otherRouter;
