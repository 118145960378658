<template>
    <el-dialog
        title=" "
        :visible.sync="dialogVisible"
        append-to-body
        :close-on-click-modal="false"
        :custom-class="dialogCustomClass"
        width="650px"
        @close="handleToClose"
    >
        <div class="login-container">
            <div class="login-title">
                <p>
                    <i class="el-icon-wx"></i>
                    <span>微信扫一扫</span>
                </p>
                <p class="login-title-desc">极速完成注册/登录</p>
            </div>

            <div v-loading="loading" class="login-ewm" @click="handleCreateQr">
                <el-image v-if="agreenValue" :src="loginEwm">
                    <div slot="error" class="image-slot">
                        <div class="ewm-error">
                            <i class="el-icon-refresh"></i>
                            <span>点击重新获取</span>
                        </div>
                    </div>
                </el-image>

                <div v-else class="login-ewm-txt">
                    <span>请勾选和同意《服务协议》《隐私协议》</span>
                </div>

                <div class="login-ewm-tips">扫码关注公众号立即登录</div>
            </div>

            <div class="login-footer">
                <el-checkbox v-model="agreenValue" size="medium"></el-checkbox>
                <span>登录即表示同意</span>
                <router-link to="/about/service">《服务协议》</router-link>
                <router-link to="/about/privacy">《隐私协议》</router-link>
            </div>
        </div>
    </el-dialog>
</template>

<script>
// import dayjs from 'dayjs';

import { ApiLoginCreateQr, ApiLoginGetQrCodeState } from '/src/api/login';

import { track } from '/src/directive/track/sendData';

export default {
    name: 'CLogin',
    // props: {
    //     exportTotal: {
    //         type: Number,
    //         default: 51629,
    //     },
    // },
    data() {
        return {
            dialogVisible: false,
            dialogCustomClass: 'login-dialog',
            loginSuccess: false,
            loading: false,

            loginEwm: '',
            // 轮询登录状态需要的key，生成二维码时返回
            loginKey: '',
            agreenValue: true,

            // 登录状态
            loginStatus: '',

            // 当前扫码动作是否是注册成功
            isReg: false,

            // 附带的参数
            loginQuery: {
                actID: '',
                key: '',
                word: '',
                service: '',
                tag: '',
                // 百度推广
                bd_vid: '',
                // 360推广
                qhclickid: '',
            },
        };
    },
    // watch: {
    //     dialogVisible(val) {
    //         // console.log('dialogVisible', val);
    //         if (this.loginStatus === 'success' && !val) {
    //             // console.log('dialogVisible', val);

    //             // $emit 莫名失效
    //             // this.$emit('success');

    //             window.location.reload();
    //         }
    //     },
    // },
    // computed: {
    //     userInfo () {
    //         return this.$store.getters.userInfo || {};
    //     },
    // },
    // created() {},
    mounted() {
        // this.handleOpen();
    },
    methods: {
        handleOpen() {
            this.isReg = false;
            this.agreenValue = true;
            this.dialogCustomClass = 'login-dialog';
            this.loginEwm = '';
            this.loginKey = '';
            this.loginStatus = '';
            this.dialogVisible = true;
            this.loginSuccess = false;

            // 获取进入页面时的参数
            const tmpQuery = this.$store.getters.accessQuery;

            if (tmpQuery) {
                this.loginQuery = JSON.parse(tmpQuery);
            }

            this.handleCreateQr();
        },

        // 生成二维码
        handleCreateQr() {
            const { loginQuery } = this;

            if (!this.agreenValue) {
                this.$message.error('请勾选同意《服务协议》《隐私协议》！');

                return false;
            }

            // this.$emit('success');

            if (this.loading || this.loginKey) {
                return false;
            }

            this.loading = true;

            // const tmpMockData = {
            //     url: 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQFq7jwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyU2NLUGdzcGFmbEMxN2l4UzF6YzgAAgR6XzZjAwRYAgAA',
            //     key: '8a04c791689d4f94acfe982187b9ded7',
            // };

            // this.loginEwm = tmpMockData.url;
            // this.loginKey = tmpMockData.key;
            // this.loading = false;

            // this.handleGetQrCodeState();

            ApiLoginCreateQr({
                ...loginQuery,
            })
                .then(res => {
                    this.loading = false;

                    // console.log(JSON.stringify(res.data));

                    if (res.data) {
                        this.loginEwm = res.data.url;
                        this.loginKey = res.data.key;

                        this.handleGetQrCodeState();
                    } else {
                        this.$message.error('获取二维码数据为空！');
                    }
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        // 轮询登录状态
        handleGetQrCodeState() {
            const { loginQuery, loginKey, dialogVisible } = this;

            if (!dialogVisible || !loginKey) {
                // 弹出框关闭，停止轮询
                return false;
            }

            // const tmpMockData = {
            //     key: 'success',
            //     value: {
            //         guid: '1519683938887401474',
            //         weiXinOpenId: 'oGUrV5uo_1J3l7_uH358Ra9WtW_Y',
            //         miniProgramOpenId: 'osDVW5ejMm_q9PfhvrA-tlHhFAKA',
            //         phone: '15019261433',
            //         weiXinNickName: '',
            //         weiXinHeadImgUrl: null,
            //         weiXinUnionId: 'ofVmr5yUxr8UFpK1itsPHvAQvquQ',
            //         enableWeixinSub: true,
            //         nickName: '位严走论现',
            //         companyName: null,
            //         expirationDate: null,
            //         expirationVipTime: null,
            //         registerSource: 'pc扫码',
            //         createTime: '1651155860050',
            //         creatorGuid: '',
            //         modifyTime: '1651155860050',
            //         modifierGuid: '',
            //         isDeleted: false,
            //         keyword: null,
            //         tag: '',
            //         actId: '',
            //         bdVid: null,
            //         token: '9fa3b5e9c4634af0a9e5060bf038d32b',
            //         personalSettingFlag: true,
            //         khzyConfig:
            //             '{"cityCode":"4403","industry":"I14","keyWord":""}',
            //         followStatus: true,
            //         tokenExpiredTime: '1665112684904',
            //         followIds: [],
            //         collectionIds: [],
            //         isCompany: true,
            //     },
            // };

            // const { key, value } = tmpMockData;

            // this.loginStatus = key;

            // this.$message.success('登录成功！');

            // this.loginSuccess = true;
            // this.dialogVisible = false;

            // // 清空保留的参数
            // this.$store.dispatch('SetAccessQuery', '');

            // this.$store
            //     .dispatch('SetToken', value.token)
            //     .then(() => {
            //         // 重新获取用户信息，更新vipFlag
            //         this.$store
            //             .dispatch('GetUserInfo')
            //             .then(() => {
            //                 // 重新获取用户信息 - 成功
            //                 this.$emit('success');
            //             })
            //             .catch(() => {});
            //     })
            //     .catch(() => {});

            ApiLoginGetQrCodeState({
                ...loginQuery,
                key: loginKey,
            })
                .then(res => {
                    // console.log(JSON.stringify(res.data));

                    if (res.data) {
                        const { key, value } = res.data;

                        this.loginStatus = key;

                        if (key === 'fail') {
                            setTimeout(() => {
                                this.handleGetQrCodeState();
                            }, 2000);
                        } else if (key === 'overdue') {
                            this.loginEwm = '';
                            this.loginKey = '';

                            this.$message.error('二维码过期，请重新获取！');
                        } else {
                            this.$message.success('登录成功！');

                            // // 是否刚注册 - 判断创建时间与当前时间在10分钟之内
                            // if (dayjs().diff(dayjs(parseInt(value.createTime)), 'minute') <= 10) {
                            //     this.dialogCustomClass = '';
                            //     this.isReg = true;
                            // } else {
                            //     this.dialogVisible = false;
                            // }

                            this.loginSuccess = true;
                            this.dialogVisible = false;

                            // 清空保留的参数
                            this.$store.dispatch('SetAccessQuery', '');

                            this.$store
                                .dispatch('SetToken', value.token)
                                .then(() => {
                                    // 重新获取用户信息，更新vipFlag
                                    this.$store
                                        .dispatch('GetUserInfo')
                                        .then(() => {
                                            // 重新获取用户信息 - 成功
                                            this.$emit('success');
                                        })
                                        .catch(() => {});
                                })
                                .catch(() => {});
                        }
                    }
                })
                .catch(() => {});
        },

        // 前往设置
        handleToSetting() {
            this.dialogVisible = false;

            const tmpRoute = this.$router.resolve({ path: '/user/setting' });

            window.open(tmpRoute.href, '_blank');
        },

        // 关闭的回调
        handleToClose() {
            if (!this.loginSuccess) {
                // 未支付成功，关闭弹出框
                // 上报
                track('t:click_signinpopup_close');
            }

            this.loginSuccess = false;
        },
    },
};
</script>

<style lang="scss">
.login-dialog {
    .el-dialog__header {
        // display: none;
        background: none;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #333;
    }

    .el-dialog__body {
        padding: 0;
    }

    .login-footer {
        .el-checkbox__inner {
            width: 20px;
            height: 20px;

            &::after {
                height: 10px;
                left: 6px;
                top: 2px;
                width: 5px;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.login-container {
    padding-top: 10px;
    text-align: center;
}

.login-title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;

    .el-icon-wx {
        margin-right: 10px;
        width: 32px;
        height: 32px;
    }

    &-desc {
        padding-top: 10px;
        font-size: 18px;
        line-height: 1;
    }
}

.login-ewm {
    margin-bottom: 35px;

    .el-image,
    &-txt {
        display: block;
        width: 210px;
        height: 210px;
        margin: 0 auto;
        background-color: #ddd;
    }

    &-txt {
        box-sizing: border-box;
        padding: 16% 20px 0;
        background-color: rgba(0, 0, 0, 30%);
        color: #fff;
    }

    &-tips {
        font-size: 18px;
        color: #959595;
    }
}

.ewm-error {
    line-height: 185px;

    .el-icon-refresh {
        margin-right: 5px;
        font-size: 16px;
    }
}

.login-footer {
    height: 58px;
    background-color: rgba(29, 111, 247, 20%);
    line-height: 58px;
    font-size: 16px;

    a {
        color: #1d6ff7;
    }

    .el-checkbox {
        margin-right: 10px;
    }
}

.reg-success {
    padding: 32px 0;
    text-align: center;

    &-ico {
        height: 68px;

        .el-icon-circle-check {
            font-size: 46px;
            color: #e23a37;
        }
    }

    &-text {
        margin-bottom: 35px;
        font-size: 14px;
        color: #535353;
        line-height: 1.2;

        .title {
            margin-bottom: 15px;
            font-size: 24px;
            color: #1d1d1d;
        }
    }

    &-btn {
        .el-button {
            width: 180px;
            height: 50px;
            padding: 0;
            border-radius: 25px;
            line-height: 50px;
            font-size: 18px;
        }
    }
}
</style>
