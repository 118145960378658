import { track } from './sendData'

export default class Click {
    add(entry) {
        // const tp = entry.el.attributes['track-params'].value
        // console.log(entry.el.attributes['track-params']);
        
        entry.el.addEventListener('click', function () {
            const tp = this.attributes['track-params'].value

            track(tp)
        })
    }
}