import Vue from 'vue';

import '/src/element-ui';
import '/src/styles/index.scss';
import '/src/permission'; // permission control

// 模拟数据
// import '/src/mock';

import App from '/src/App.vue';
import router from '/src/router';
import store from '/src/store';

// 自定义指令
import '/src/directive/track/index';

// 自定义过滤器
import * as filters from '/src/filters';

// 导出的是对象，可以直接通过 key 和 value 来获得过滤器的名和过滤器的方法
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

// 生成订单支付
import OrderPay from '/src/components/Dialog/orderPay.vue';
// 登录
import Login from '/src/components/Dialog/login.vue';
// 购买vip
import BuyVip from '/src/components/Dialog/buyVip.vue';

// 注册全局组件
Vue.component('OrderPay', OrderPay);
Vue.component('Login', Login);
Vue.component('BuyVip', BuyVip);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});
