<template>
    <el-dialog
        title="开通VIP"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        append-to-body
        width="654px"
    >
        <div class="buy-body">
            <div class="pay-list">
                <template v-for="item in payData">
                    <div
                        v-if="!item.hidden"
                        :key="item.name"
                        class="pay-item"
                        :class="payItemClass(item)"
                        @click="handleCheckPay(item)"
                    >
                        <div class="pay-item-label">
                            <div class="pay-item-text">
                                <p class="vip-text">
                                    <span>开通</span>
                                    <i class="el-icon-vip"></i>
                                    <span>会员</span>
                                    <!-- <span> {{ item.title }}</span> -->
                                </p>
                                <p>
                                    每天可在线查看{{
                                        item.viewedAmount
                                    }}份完整报告
                                </p>
                            </div>
                        </div>
                        <div class="pay-item-content">
                            <div
                                v-if="item.rankField === 3"
                                class="pay-item-special"
                            >
                                限时折扣
                            </div>
                            <div class="pay-item-text">
                                <div class="price">
                                    <span class="currency">￥</span>
                                    <span class="amount">{{
                                        item.vipAmount
                                    }}</span>
                                    <span class="unit"
                                        >/{{ item.vipUnit }}</span
                                    >
                                </div>
                                <div class="notice">{{ item.vipDesc }}</div>
                            </div>
                        </div>
                        <div class="pay-item-input">
                            <div class="radio">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <div class="pay-statement">
                <div class="pay-statement-content">
                    <p>· 成为VIP会员，每天可<em>在线查看完整报告</em></p>
                    <p>· VIP会员可<em>下载报告原件</em>到本地</p>
                    <p>
                        ·
                        VIP会员可使用<em>搜索与高级筛选功能</em>，快速准确找到目标客户资源
                    </p>
                    <p>· VIP会员<em>没有翻页限制</em>，可查看所有报告列表</p>
                    <p>
                        · 该产品为虚拟服务产品，目前不支持退款操作，请谨慎付费
                    </p>
                    <p><a href="/membership/index">查看更多会员权益>></a></p>
                </div>

                <div class="pay-statement-kefu">
                    <el-image src="/images/kf_300_300.png">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <p>扫码添加<br />24小时客服在线答疑</p>
                </div>
            </div>

            <div class="buy-footer">
                <div class="buy-price">
                    <span class="label">实付金额：</span>
                    <span class="currency">￥</span>
                    <span class="amount">{{
                        payAmount | filterThousandth
                    }}</span>
                </div>
                <div class="invoice-tips">支付后可开发票</div>
                <div class="pay-btn">
                    <el-button type="red" @click="handleToPay"
                        >开通VIP</el-button
                    >
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { ApiCreateOrder } from '/src/api/order';

import { getPayData, getExportCfg } from '/src/utils/staticData';

export default {
    name: 'CBuyVip',
    // props: {
    //     exportTotal: {
    //         type: Number,
    //         default: 0,
    //     },
    // },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            sending: false,

            exportStatus: '',
            exportTotal: 0,

            // maxFreeTime: 5,
            // todayFreeTime: 5,

            payCurrentGuid: '',
            payAmount: 0,

            // 开通类型 1-660元三年会员 2-330元一年会员 3-包季服务 4-一次性导出
            payData: [],

            vipExportCfg: {
                exportTimes: '',
                exportNumber: '',
            },
        };
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {};
        },
    },
    // created() {},
    mounted() {
        // this.handleOpen();
    },
    methods: {
        handleOpen(payGuid) {
            this.dialogVisible = true;

            // 获取用户信息 - 其他客户端可能进行了支付等操作，需要同步信息
            this.$store
                .dispatch('GetUserInfo')
                .then(() => {
                    this.handleVipData(payGuid);
                    this.handleExportCfg();
                })
                .catch(() => {});
        },

        // 获取会员导出配置数据
        handleExportCfg() {
            const that = this;
            const { filterThousandth } = that.$options.filters;

            getExportCfg(data => {
                const exportTimes = data.exportTimes;
                const exportNumber = filterThousandth(data.exportNumber);

                this.vipExportCfg = {
                    exportTimes,
                    exportNumber,
                };
            });
        },

        // 获取vip数据
        handleVipData(payGuid) {
            const that = this;

            getPayData(data => {
                let tmpCurrentItem = {};
                let defaultItem = {};

                data.forEach(item => {
                    item.vipUnit = item.vipDuration + '个月';

                    if (item.vipDuration >= 12 && item.vipDuration % 12 === 0) {
                        item.vipUnit = parseInt(item.vipDuration / 12) + '年';
                    }

                    if (payGuid === item.guid) {
                        tmpCurrentItem = item;
                    } else if (item.defaultFlag) {
                        defaultItem = item;
                    }
                });

                if (!payGuid || !tmpCurrentItem.guid) {
                    if (defaultItem.guid) {
                        tmpCurrentItem = defaultItem;
                    } else {
                        tmpCurrentItem = data[0];
                    }
                }

                that.payData = data;

                that.handleCheckPay(tmpCurrentItem);
            });
        },

        // // 数据初始化
        // handleDataFormat() {
        //     const { payCurrentGuid, payData } = this;

        //     payData.forEach((item) => {
        //         if (payCurrentGuid === item.type) {
        //             this.handleCheckPay(item);
        //         }
        //     });
        // },

        handleCheckPay(item) {
            this.payCurrentGuid = item.guid;
            this.payAmount = item.vipAmount;
        },

        payItemClass(item) {
            const { payCurrentGuid } = this;
            let tmpClass = ['pay-item__' + item.guid];

            if (payCurrentGuid === item.guid) {
                tmpClass.push('checked');
            }

            return tmpClass.join(' ');
        },

        handleToPay() {
            const { payCurrentGuid, payData } = this;
            const tmpOrderData = payData.filter(
                item => item.guid === payCurrentGuid
            );

            if (this.sending) {
                return false;
            }

            if (tmpOrderData[0]?.guid) {
                this.sending = true;

                ApiCreateOrder({
                    // 开通类型 会员传guid 一次性导出传4
                    openType: tmpOrderData[0].guid,
                })
                    .then(res => {
                        this.sending = false;
                        this.dialogVisible = false;

                        this.$emit('confirm', res.data);
                    })
                    .catch(() => {
                        this.sending = false;
                    });
            } else {
                this.$message.error('支付数据为空！');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import './payList.scss';

.buy-body {
    padding: 34px 47px 16px;
}

.pay-item-subtitle {
    font-size: 13px;
    color: #333;
}

.buy-footer {
    display: flex;
    line-height: 50px;

    .buy-price {
        flex: 1;
        font-weight: bold;
        font-size: 36px;
        color: #e03a3e;

        .label {
            padding-left: 2px;
            font-size: 18px;
            color: #474747;
        }
    }

    .invoice-tips {
        width: 100px;
        font-size: 12px;
        color: #535353;
    }

    .pay-btn {
        width: 180px;

        .el-button {
            padding: 0;
            width: 100%;
            height: 50px;
            border-radius: 25px;
            line-height: 50px;
            font-size: 18px;
        }
    }
}
</style>
