import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// 获取原型对象上的push函数
const originalPush = Router.prototype.push;
// 修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

/* Layout */
import homeRouter from './modules/home';
import aboutRouter from './modules/about';
import interfaceRouter from './modules/interface';
import userRouter from './modules/user';
import otherRouter from './modules/other';
import membershipRouter from './modules/membership';

export const constantRouterMap = [
    ...otherRouter,

    // 首页
    homeRouter,
    aboutRouter,
    userRouter,
    membershipRouter,
    interfaceRouter,

    // {
    //     path: '/test',
    //     component: () => import('/src/views/test.vue'),
    //     hidden: true,
    //     name: 'testPage',
    //     meta: {
    //         white: true,
    //         title: '测试',
    //         white: true,
    //     },
    // },
];

const createRouter = () =>
    new Router({
        mode: 'history', // require service support
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRouterMap,
    });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;

// export default new Router({
//     // mode: 'history', //后端支持可开
//     scrollBehavior: () => ({
//         y: 0,
//     }),
//     routes: constantRouterMap,
// });
