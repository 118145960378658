import Layout from '/src/layout/Layout.vue';

const homeRouter = {
    path: '/',
    component: Layout,
    // redirect: '/index',
    name: 'home',
    meta: { title: '首页' },
    children: [
        {
            path: '',
            component: () => import('/src/views/home/index.vue'),
            name: 'homeIndex',
            meta: { title: '首页', navCurrent: 'index' },
        },
        {
            path: 'detail/:id',
            component: () => import('/src/views/home/detail.vue'),
            name: 'homeDetail',
            meta: { title: '详情' },
        },
    ],
};

export default homeRouter;
